import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';

import {Checkbox, Button, TextField} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import * as CartStore from 'stores/Cart';
import * as NetworkStore from 'stores/Network';
import {AccountStatus} from 'stores/Network';
import * as UserStore from 'stores/User';
import * as DashboardStore from 'stores/Dashboard';
import * as Socket from 'stores/socket';
import * as BloomReachStore from 'stores/BloomReach';
import * as SystemStore from 'stores/System';
import * as ReportingStore from 'stores/Reporting';
import {clearToken, getCookie, logEvent, translateHomeScreen, isLocalStorageAvailable} from 'utility';
import 'less/login.less';
import Spinner from 'components/Spinner';
import OnCreditHoldModal from 'components/OnCreditHoldModal';
import FacilityDialog from 'components/FacilitySearchDialog';
@withRouter
@connect(
    state => ({
        configuration: state.system.configuration,
        siteSettings: state.system.siteSettings,
        system: state.system,
        network: state.network,
        params: state.params,
        reporting: state.reporting,
    }),
    _.merge({},
        NetworkStore.actionCreators,
        UserStore.actionCreators,
        CartStore.actionCreators,
        DashboardStore.actionCreators,
        Socket.actionCreators,
        BloomReachStore.actionCreators,
        SystemStore.actionCreators,
        ReportingStore.actionCreators,
    )
)
export default class Login extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...NetworkStore.ActionShape,
        ...UserStore.ActionShape,
        ...CartStore.ActionShape,
        ...DashboardStore.ActionShape,
    };

    constructor(props) {
        super(props); 
        this.state = {
            showPassword: false,
            rememberMe: true,
            loggingIn: false,
            loggingFail: false,
            passwordExpired: false,
            username: Cookies.get('id') || '',
            password: '',
            errors: {},
            touched: {username: false, password: false},
            showCreditHoldModal: false,
            showFacilitySelector: false
        };
    }

    componentDidMount = () => {
        const {params, network: {sessionStorageAvailable}} = this.props;
        const values = this.state;
        clearToken();
        if (sessionStorageAvailable) {
            sessionStorage.removeItem('wo_id');
            sessionStorage.removeItem('urlFields');
            sessionStorage.removeItem('fields');
            sessionStorage.removeItem('userFields');
            sessionStorage.removeItem('prevUrl');
        }
        Cookies.set('rememberMe', true, {expires: 30, secure: window.location.hostname !== 'localhost', sameSite: 'None'});

        if (params.e) {
            values.username = params.ak1;
            values.password = params.ak2;
            sessionStorageAvailable && sessionStorage.removeItem('prevUrl');
            this.loginUser();
        }
    }

    validate = (values) => {
        let errors = {};

        if (/^[\s].+$/i.test(values.username)) {
            errors.username = 'Remove space';
        }

        if (!values.username) {
            errors.username = 'Name is required';
        }
        if (!values.password) {
            errors.password = 'Password is required';
        }
        return errors;
    }

    updateErrors = (values) => {
        const errors = this.validate(values);
        this.setState({errors});
        return Object.keys(errors).length;
    }

    displayErrors = (currentField = '') => {
        const {touched: alreadyTouched, ...otherState} = this.state;
        const errors = this.validate(this.state);
        let touched = _.mapValues(alreadyTouched, (v, k) => !!(alreadyTouched[k] || otherState[k] || k === currentField));

        this.setState({errors, touched});
        return Object.keys(errors).length;
    }

    Redirect = (ak1, ak2, pathname) => {
        const {getSettings, history} = this.props;
        getSettings().then(settings => {
            const url = settings.response.data.url;
            if (url !== '' && window.location.hostname !== 'localhost' && window.location.href.indexOf('partssource.com') == -1)
                window.location.replace(url + pathname + '?ak1=' + ak1 + '&ak2=' + ak2);

            else
                history.push(pathname);
            return;
        });

    }
    loginUser = (e) => {
        e && e.preventDefault();
        const values = this.state;

        if (this.displayErrors()) return;
        const {
            login,
            setUserId,
            logout,
            getSettings,
            history,
            loadUserCart,
            getFacilities,
            saveUserReduxState,
            setExpiredUser,
            resetPath,
            setFilters,
            configuration: {features: {notifications, dashboard}},
            network: {tokenInfo: {subscriptionId}, sessionStorageAvailable},
        } = this.props;
        this.setState({loggingIn: true, loggingFail: false, passwordExpired: false});

        login(values).then(x => {
            if (x.type && x.type == 'LOGIN_ERR') {
                this.setState({loggingIn: false, loggingFail: true});
                return;
            }
            const {response: {status, data}} = x;
            if (data.isCADisabled) { // is user disabled form customer admin
                this.setState({loggingIn: false, loggingFail: true, passwordExpired: false});
                return;
            }
            resetPath();

            if (status === 200) {
                sessionStorageAvailable && sessionStorage.removeItem('fields');
                Cookies.remove('browsingHistory');                                
                const ak1 = data.authenticationKey;
                const ak2 = data.authorizationKey;
                if (data.status && process.env.REACT_APP_INSIGHTS_KEY) {
                    window.appInsights.setAuthenticatedUserContext(data.userId.toString(), data.rootCompanyId.toString(), true);
                    window.appInsights.context.user.id = data.userId.toString();
                }

                if (data.isEmployee) {
                    const params = new URLSearchParams(window.location.search);
                    const returnUrl = params.get('returnUrl');

                    if (returnUrl) 
                        history.push(returnUrl);
                    else if (data.apolloAdminRole == 4)
                        history.push('/admin/rfq');
                    else
                        history.push('/admin/company')

                    return;
                }

                // TODO: If the user needs to accept the EULA or change password,
                // none of the other things in the login flow will have happened.
                // (no settings loaded)

                if (data.status & AccountStatus.EulaRequired || data.status & AccountStatus.PasswordExpired) {
                    getSettings().then(settings => {
                        let user = settings.response.data.settings;
                        loadUserCart();
                        getFacilities().then(x => {
                            if (x.response && x.response.data) {
                                let defaultFacility = _.find(x.response.data, f => _.toNumber(f.facilityId) === _.toNumber(user.defaultFacilityId));

                                if (!defaultFacility && x.response.data.length === 1)
                                    defaultFacility = x.response.data[0];

                                if (defaultFacility) {
                                    setFilters({
                                        facilities: [
                                            {
                                                id: defaultFacility.facilityId,
                                                name: defaultFacility.facilityName,
                                            },
                                        ],
                                        date: {
                                            from: moment().subtract(366, 'days').format('YYYY/MM/DD'),
                                            to: moment().subtract(1, 'days').format('YYYY/MM/DD'),
                                        },
                                    });
                                    saveUserReduxState({facility: defaultFacility});
                                }
                            }

                            if (data.status & AccountStatus.EulaRequired) {
                                this.Redirect(ak1, ak2, '/policies-acceptance');
                                return;
                            } else if (data.status & AccountStatus.PasswordExpired)
                                this.Redirect(ak1, ak2, '/changepassword');
                        });
                    })

                    return;

                }

                if (data.status === AccountStatus.Valid) {
                    getSettings().then(settings => {
                        let user = settings.response.data.settings;
                        const { privacyPolicyAccepted, eulaRequired } = settings.response.data.userInfo;

                        setUserId(data.loginId, data.firstName, data.lastName, subscriptionId, false, eulaRequired);
                        const userId = data.loginId;

                        if (dashboard && notifications && user.hasDashboardv2Access) {
                            this.props.connectToWebsocket(user.showTechNames, user.showAllRecords, user.showAllRepairs, user.showOtherTechQuotes);
                        }

                        if (user.hasReportsAccess) {
                            axios.post('/AuthenticationService/api/v1.0/sisense/initiate');
                            Cookies.remove('sisense');
                        }
                        loadUserCart();
                        getFacilities().then(x => {
                            if (x.response && x.response.data) {
                                let defaultFacility = _.find(x.response.data, f => _.toNumber(f.facilityId) === _.toNumber(user.defaultFacilityId));
                                
                                if (!defaultFacility && x.response.data.length === 1)
                                    defaultFacility = x.response.data[0];

                                if (defaultFacility) {
                                    setFilters({
                                        facilities: [
                                            {
                                                id: defaultFacility.facilityId,
                                                name: defaultFacility.facilityName,
                                            },
                                        ],
                                        date: {
                                            from: moment().subtract(366, 'days').format('YYYY/MM/DD'),
                                            to: moment().subtract(1, 'days').format('YYYY/MM/DD'),
                                        },
                                    });
                                    saveUserReduxState({facility: defaultFacility});
                                }
                                if (user.creditHold) {
                                    if (isLocalStorageAvailable()) {
                                        localStorage.setItem('eulaRequired',eulaRequired);
                                        localStorage.setItem('ak1',ak1);
                                        localStorage.setItem('ak2',ak2);
                                        localStorage.setItem('userId',userId);
                                        localStorage.setItem('defaultHomeScreen',user.defaultHomeScreen);
                                    }

                                    if (x.response.data.every(f => f.creditHold === true)) {
                                        this.setState({showCreditHoldModal: true});
                                        return
                                    }
                                    else if (x.response.data.some(f => f.creditHold === false) && x.response.data.some(f => f.creditHold === true) && !defaultFacility) {
                                        this.setState({showFacilitySelector: true})
                                        return;
                                    }
                                }
                                // After user is loaded, if privacy policy has changed, ask the user to either accept it ot
                                if (eulaRequired) {
                                    this.Redirect(ak1, ak2, '/policies-acceptance');
                                    return;
                                }

                                if (sessionStorageAvailable && sessionStorage.prevUrl && sessionStorage.prevUrl !== '/' &&
                                 !sessionStorage.prevUrl.toLowerCase().includes('login') && sessionStorage.prevUrl !== '/changepassword') {
                                    history.push(sessionStorage.prevUrl);
                                } else {
                                    this.Redirect(ak1, ak2, translateHomeScreen(user.defaultHomeScreen));
                                    this.TrackLogin(status, userId);
                                }
                            }
                        });
                    });
                } else if (data.status & AccountStatus.Invalid) {
                    logout();
                    setExpiredUser(values.username);
                    this.Redirect(ak1, ak2, '/changepassword');
                } else {
                    this.setState({loggingIn: false, loggingFail: true});
                }

            } else {
                this.setState({loggingIn: false, loggingFail: true});
                if (status == null) {
                    this.TrackLogin(status);
                }
            }
        });
    }

    TrackLogin = (status, userId) => {
        logEvent('LOGIN', {
            'Login': status === 200 ? 'true' : 'false',
            'redirect': location.href,
        });
        logEvent('UserID Type', {
            'UserID Type': userId.toString().includes('@') ? 'Email' : 'Numeric',
        });
    }

    getCookieAcceptanceValue = () => {
        const cookieName = 'cookie-policy';

        if (getCookie(cookieName))
            return true;
        else
            return false;
    };

    handleRememberMeChange = (isChecked) => {
        this.setState({rememberMe: isChecked});

        console.log(this.state.rememberMe)
        Cookies.set('rememberMe', isChecked, {expires: 30, secure: window.location.hostname !== 'localhost'});
    }

    togglePasswordVisibility = () => {
        const {showPassword} = this.state;
        this.setState({showPassword: !showPassword});
    }

    handleFieldChange = (field, e) => {
        let newState = {...this.state, [field]: e.target.value};
        this.setState(newState);
        this.updateErrors(newState);
    }
    continueWithRedirect = () => {
        const {network: {sessionStorageAvailable}, history} = this.props;
        
        let eulaRequired = localStorage.getItem('eulaRequired');
        let ak1 = localStorage.getItem('ak1');
        let ak2 = localStorage.getItem('ak2');
        let userId = localStorage.getItem('userId');
        let defaultHomeScreen = localStorage.getItem('defaultHomeScreen');
        localStorage.removeItem('eulaRequired');
        localStorage.removeItem('ak1');
        localStorage.removeItem('ak2');
        localStorage.removeItem('userId');
        localStorage.removeItem('defaultHomeScreen');
        if (eulaRequired==="true") {
            this.Redirect(ak1, ak2, '/policies-acceptance');
            return;
        }
        if (sessionStorageAvailable && sessionStorage.prevUrl && sessionStorage.prevUrl !== '/' &&
         !sessionStorage.prevUrl.toLowerCase().includes('login') && sessionStorage.prevUrl !== '/changepassword') {
            history.push(sessionStorage.prevUrl);
        } else {
            this.Redirect(ak1, ak2, translateHomeScreen(defaultHomeScreen));
            this.TrackLogin(200, userId);
        }
    }

    cancelFacilitySelection = () => {
        this.setState({showFacilitySelector: false})
        this.continueWithRedirect();
    }

    onSelectFacility = (facility) => {
        const {saveUserReduxState} = this.props;
        saveUserReduxState({facility: facility});
        this.setState({showFacilitySelector: false});
        this.continueWithRedirect();
    }

    render() {
        const {loggingIn, username, password, errors, touched, showPassword, rememberMe, passwordExpired, loggingFail, showCreditHoldModal, showFacilitySelector} = this.state;
        const { system: {siteSettingsLoaded} } = this.props;
        const eyeballClass = showPassword ? 'fa fa-eye-slash security-input-eye show' : 'fa fa-eye-slash security-input-eye';

        return (<div className="login-form">
            <PageMetaData
                title="Login"
                pageType="other"
                trackAnalytics={true} />
            { !siteSettingsLoaded ? 
            <Spinner /> : 
            <div className="login-content">
                <h1>Sign In</h1>
                <form onSubmit={this.loginUser}>
                    <div>
                        <TextField type="text" id="username" autoFocus={true} tabIndex="1" value={username} onChange={e => this.handleFieldChange('username', e)} onBlur={() => this.displayErrors('username')} showErrorMessage={!!(errors.username && touched.username)}
                            placeholder="Email or Username" label="Email or Username" text={username} />
                        <TextField type={showPassword ? 'text' : 'password'} id="password" tabIndex="2" value={password} onChange={e => this.handleFieldChange('password', e)} onBlur={() => this.displayErrors('password')} showErrorMessage={!!(errors.password && touched.password)}
                            placeholder="Password" label="Password" className="security-input"
                        />
                        <i className={eyeballClass} aria-hidden="true" onClick={this.togglePasswordVisibility} />

                        <Checkbox key={`checkbox-remember-me`} label={'Remember Me'} checked={rememberMe} onChange={() => this.handleRememberMeChange(!rememberMe)} />
                        <Button disabled={Object.keys(errors).length > 0} secondary={true} type="submit" loading={loggingIn}>Sign In</Button>
                        <NavLink to="/register" className="not-registered">Not Registered?</NavLink>
                        {!passwordExpired && <div><span style={{float: 'left', width: '72px'}}>Forgot your&nbsp; </span> <NavLink style={{float: 'left'}} to="/forgotusername" className="forgot-username">username</NavLink> <span style={{float: 'left'}}> &nbsp; or &nbsp;</span>
                            <NavLink to="/forgot" className="forgot-username" style={{float: 'left'}}>password? </NavLink>
                        </div>}
                    </div>

                    {loggingFail
                        && <div className="row" style={{marginBottom: '20px', marginTop: '40px'}}>
                            <h4 style={{color: 'red', fontWeight: 'bold'}}>Invalid username and/or password.</h4>
                        </div>}
                    {showCreditHoldModal &&
                        <OnCreditHoldModal onSubmit={this.continueWithRedirect}/>
                    }
                    {showFacilitySelector && (
                        <FacilityDialog 
                            onSubmit={this.onSelectFacility}
                            onCancel={this.cancelFacilitySelection} />
                    )}
                </form>
            </div>
            }
        </div>);
    }
}
